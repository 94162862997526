/* We can't use the WP navigation for the submenu items of tools, since they
 * are not regular pages.
 * */

export const submenuItems = [
  {
    title: 'JT-Ready',
    uri: '/tools/jtready/',
  },
  {
    title: 'Coping Strategies',
    uri: '/tools/coping-strategies/',
  },
  {
    title: 'Inventory of Coping Strategies',
    uri: '/tools/inventory/',
  },
  {
    title: 'Maps',
    uri: 'https://storymaps.arcgis.com/collections/263f2045c6c54b9ab120755369d64756',
  },
  /*  {
    title: 'Overview',
    uri: '/tools/coping-strategies/overview/',
  },
  {
    title: 'Resist',
    uri: '/tools/coping-strategies/resist/',
  },
  {
    title: 'Adapt',
    uri: '/tools/coping-strategies/adapt/',
  },
  {
    title: 'Reform',
    uri: '/tools/coping-strategies/reform/',
  },*/
];
